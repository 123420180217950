import Section from "../Section"
import {
    FaInstagram,
    FaLinkedin,
    FaGithub,
    FaStackOverflow
} from 'react-icons/fa';

const ContactSection = () => {
    return (
        <Section id="contact-section">
            <div className="w-full flex flex-col justify-center align-center text-2xl font-semibold text-center">
                <div className="text-3xl mb-10">
                    Lets get in touch!
                </div>
                <div>
                    Email me at <a href="mailto:keegan.woodburn@gmail.com">keegan.woodburn@gmail.com</a>
                </div>
                <div className="mt-2">
                    Call me at <a href="tel:1-240-319-3593">240-319-3593</a>
                </div>
                <div className="mt-2">
                    Or check me out at any of these places:
                </div>
                <ul className="mx-auto mb-10 mt-4 flex items-center" aria-label="Social media">
                    <li className="mr-5 text-2xl">
                        <a className="block hover:text-slate-200" href="https://github.com/thedeafone/" target="_blank" rel="noreferrer noopener" aria-label="GitHub (opens in a new tab)">
                            <FaGithub size={40} />
                        </a>
                    </li>
                    <li className="mr-5 text-2xl">
                        <a className="block hover:text-slate-200" href="https://instagram.com/thefakekeeganw/" target="_blank" rel="noreferrer noopener" aria-label="Instagram (opens in a new tab)">
                            <FaInstagram size={40} />
                        </a>
                    </li>
                    <li className="mr-5 text-2xl">
                        <a className="block hover:text-slate-200" href="https://stackoverflow.com/users/10181378/thedeafone/" target="_blank" rel="noreferrer noopener" aria-label="Stack overflow (opens in a new tab)">
                            <FaStackOverflow size={40} />
                        </a>
                    </li>
                    <li className="mr-5 text-2xl">
                        <a className="block hover:text-slate-200" href="https://www.linkedin.com/in/keegan-woodburn/" target="_blank" rel="noreferrer noopener" aria-label="LinkedIn (opens in a new tab)">
                            <FaLinkedin size={40} />
                        </a>
                    </li>
                </ul>
            </div>
        </Section>
    )
}

export default ContactSection