import { useEffect, useRef, useState } from "react";
import AboutSection from "./sections/AboutSection/AboutSection";
import Navbar from './sections/Navbar/Navbar';
import ProjectsSection from "./sections/ProjectsSection/ProjectsSection";
import WelcomeSection from './sections/WelcomeSection/WelcomeSection';
import ContactSection from "./sections/ContactSection/ContactSection";
import ExperienceSection from "./sections/ExperienceSection/ExperienceSection";
import './styles.css';

function Home() {
  const [newClientX, setClientX] = useState(0);
  const [newClientY, setClientY] = useState(0);

  const handleMouseMove = (e: any) => {
    const { clientX, clientY } = e;
    setClientX(clientX);
    setClientY(clientY);
  }

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const body = document.body;
    const height = wrapperRef.current!.getBoundingClientRect().height - 1;
    const speed = 0.08;
    let offset = 0;
    body.style.height = Math.floor(height) + "px";
    const smoothScroll = () => {
      offset += (window.scrollY - offset) * speed;
      // let scroll = "translateY(-" + offset + "px) translateZ(0)";
      // wrapperRef.current!.style.transform = scroll;
      requestAnimationFrame(smoothScroll);
    }
    smoothScroll();
  }, [])

  return (
    <div className='bg-background-950 text-text-50 h-full w-full' onMouseMove={handleMouseMove}>
      <div className="pointer-events-none w-full h-screen fixed backdrop-blur-3xl z-[21]" />
      <div
        className='bg-white z-20 h-60 w-60 fixed rounded-full pointer-events-none origin-center bg-gradient-to-r from-accent-500 to-primary-950 animate-gradient-rotate'
        style={{
          left: newClientX,
          top: newClientY,
        }}
      />
      <Navbar />
      <div className="h-full w-full" ref={wrapperRef} >

        <WelcomeSection />
        <AboutSection />
        <ExperienceSection />
        <ProjectsSection />
        <ContactSection />
      </div>
    </div>
  );
}

export default Home;
