import { useEffect, useRef } from "react";
import TDGameOfLife from '../../resources/images/3d-game-of-life.png';
import ChessBoard from '../../resources/images/chess-board.png';
import Colorful from '../../resources/images/colorful.png';
import PegSolitaireBoard from '../../resources/images/peg-solitaire-board.png';
import Schedulite from '../../resources/images/schedulite.png';
import SudokuBoard from '../../resources/images/solved-sudoku-board.png';
import Section from "../Section";
// import { motion } from "framer-motion";

const ProjectsSection = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const galleryRef = useRef<HTMLDivElement>(null);
    // const [isMouseDown, setIsMouseDown] = useState(false);
    // const [mouseDownAt, setMouseDownAt] = useState(0);
    // const [prevPercentMoved, setPrevPercentMoved] = useState(0);
    // const [percentMoved, setPercentMoved] = useState(0);
    // const navigate = useNavigate();
    const projects = [
        {
            img: SudokuBoard,
            title: "",
            redirect: "https://github.com/TheDeafOne/AlphaSudoku"
        },
        {
            img: ChessBoard,
            title: "",
            redirect: "https://github.com/TheDeafOne/Knightmare"
        },
        {
            img: PegSolitaireBoard,
            title: "",
            redirect: "https://github.com/TheDeafOne/peg-solitaire-solver"
        },
        {
            img: TDGameOfLife,
            title: "",
            redirect: "https://github.com/freycp20/3dGameOfLifeGit"
        },
        {
            img: Schedulite,
            title: "",
            redirect: "https://github.com/TheDeafOne/ScheduLite"
        },
        {
            img: Colorful,
            title: "",
            redirect: "https://github.com/TheDeafOne/Colorful"
        },
    ]

    // const handleMouseDown = (e: any) => {
    //     e.preventDefault();
    //     setMouseDownAt(e.clientX);
    //     setIsMouseDown(true);
    // }

    // const handleMouseMove = (e: any) => {
    // possibly use delta to control scroll and let scroll logic handle horizontal movement
    //     if (!isMouseDown) return;
    //     // if (mouseDownAt == 0) return;
    //     const mouseDelta = mouseDownAt - e.clientX;
    //     const maxDelta = window.innerWidth / 2;
    //     const percentage = (mouseDelta / maxDelta) * -100;
    //     const nextPercentage = Math.max(Math.min(prevPercentMoved + percentage, 0), -100);
    //     setPercentMoved(nextPercentage)
    //     galleryRef.current!.style.transform = `translate(${nextPercentage}%, 0%)`;
    //     galleryRef.current!.animate({
    //         transform: `translate(${nextPercentage}%, 0%)`
    //     }, { duration: 1200, fill: "forwards" });

    //     for (let i = 0; i < galleryRef.current!.children.length; i++) {
    //         const child = (galleryRef.current!.children as HTMLCollectionOf<HTMLElement>)[i]
    //         child.animate({
    //             objectPosition: `${100 + nextPercentage}% center`
    //         }, { duration: 1200, fill: "forwards" });
    //     }
    // }

    // const handleMouseUp = (e: any) => {
    //     setPrevPercentMoved(percentMoved);
    //     setIsMouseDown(false);
    // }

    useEffect(() => {
        // window.onmouseup = e => {
        //     setIsMouseDown(false);
        // }

        window.addEventListener('scroll', (e) => {
            if (containerRef.current !== null) {
                transform(containerRef.current);
            }
        })
    }, [])

    const transform = (section: HTMLDivElement) => {
        // const offsetTop = section.parentElement.offsetTop;
        let percentage = ((section.offsetTop / containerRef.current!.offsetHeight) * -100)
        // setPercentMoved(percentage);
        // setPrevPercentMoved(percentage);
        galleryRef.current!.animate({
            transform: `translate(${percentage}%, 0%)`
        }, { duration: 1200, fill: "forwards" });

        for (let i = 0; i < galleryRef.current!.children.length; i++) {
            const child = (galleryRef.current!.children as HTMLCollectionOf<HTMLElement>)[i]
            child.animate({
                objectPosition: `${100 + percentage}% center`
            }, { duration: 1200, fill: "forwards" });
        }
    }


    return (
        <Section id="projects-section">

            <div className="h-[100vw]">
                <div className="h-screen relative overflow-x-hidden sticky top-1/4" ref={containerRef}>
                    <div className="text-center">
                        <div className="text-4xl font-semibold mb-10">Projects</div>
                    </div>
                    <div className="absolute left-1/2 w-[120vw]" ref={galleryRef}>  {/*onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} draggable="false">*/}
                        {projects.map((project, i) => {
                            return (
                                <img
                                    key={i}
                                    onClick={() => window.open(project.redirect, '_blank')}
                                    src={project.img}
                                    alt={project.title}
                                    // whileHover={{scale:1.1}}
                                    className="inline-block w-[18vw] h-[50vh] object-cover object-[100%_50%] select-none mr-5 hover:cursor-pointer"
                                    onDragStart={() => false}
                                    draggable="false"
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </Section >
    )
}

export default ProjectsSection