import anime from "animejs";
import { useEffect, useRef, useState } from "react";
import Section from "../Section";


const AboutSection = () => {
    // const [isObserved, setIsObserved] = useState(false);
    const sectionRef = useRef<HTMLDivElement>(null)



    useEffect(() => {
        let didObserve = false;
        let tempSectionRef = sectionRef.current;
        const observer = new IntersectionObserver((entries: any) => {
            const [entry] = entries
            if (entry.isIntersecting && !didObserve) {
                anime(
                    {
                        targets: ".about-line",
                        translateY: ['100%', '0%'],
                        opacity: 1,
                        delay: anime.stagger(200),
                        from: 'first',
                        easing: 'easeOutCirc',
                    }
                );
                didObserve = true;
            }
        }, {
            rootMargin: '40px',
            threshold: 0.3
        });
        if (tempSectionRef) {
            observer.observe(tempSectionRef);
        }

        return () => {
            if (tempSectionRef) {
                observer.unobserve(tempSectionRef);
            }
        }
    }, [sectionRef])

    const [aboutLines, setAboutLines] = useState<any[]>([])

    // const AdjustAboutLines = () => {
    //     if (window.innerWidth > 1280) {
    //         setAboutLines([
    //             "I'm a software developer with years",
    //             "of experience in building sleek",
    //             "full-stack applications and efficient",
    //             "automation solutions.",
    //             (<div className="h-5" />),
    //             "I'm passionate about finding",
    //             "clever ways to solve complex",
    //             "problems and love learning new",
    //             "ways to innovate and create.",
    //         ]);
    //     } else {
    //         setAboutLines([
    //             "I'm a software developer with",
    //             "years of experience in",
    //             "building sleek full-stack ",
    //             "applications and efficient",
    //             "automation solutions.",
    //             (<div className="h-5" />),
    //             "I'm passionate about finding",
    //             "clever ways to solve complex",
    //             "problems and love learning new",
    //             "ways to innovate and create.",
    //         ])
    //     }
    // }

    useEffect(() => {
        // AdjustAboutLines();
        // window.addEventListener('resize', AdjustAboutLines);
        setAboutLines([
            "I'm a software developer with years",
            "of experience in building sleek",
            "full-stack applications and efficient",
            "automation solutions.",
            (<div className="h-5" />),
            "I'm passionate about finding",
            "clever ways to solve complex",
            "problems and love learning new",
            "ways to innovate and create.",
        ]);
    }, [])

    return (
        <Section id="about-section">
            <div className="text-text flex h-[90vh] flex-row" ref={sectionRef}>
                <div className="w-1/2 flex flex-col ml-32 mt-12">
                    <div>
                        {aboutLines.map((line, i) => {
                            return (
                                <div key={i} className="overflow-hidden 2xl:h-16 lg:h-12">
                                    <div className="about-line transform opacity-0 translate-y-full 2xl:text-5xl xl:text-4xl lg:text-4xl md:text-3xl pt-1">
                                        {line}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default AboutSection