// import anime from "animejs";
import { useEffect, useRef, useState } from "react";
import APL from '../../resources/logos/companies/apl-logo.png';
import Bentley from '../../resources/logos/companies/bentley-logo.png';
import Qorvo from '../../resources/logos/companies/qorvo-logo.png';
import Section from "../Section";

const TimelineNode = ({ experience, left, scrollTop }: any) => {
    const nodeRef = useRef<HTMLImageElement | null>(null);
    // const [movingNode, setMovingNode] = useState<boolean>(false);
    // const [nodeIn, setNodeIn] = useState<boolean>(false);
    // const [nodePosition, setNodePosition] = useState<number>(0);
    // const direction = left ? "left" : "right";
    const reverseDirection = left ? "right" : "left";
    const cardRef = useRef<HTMLDivElement | null>(null);
    const Card = () => {

        return (
            <div className="w-1/2" id={`experience-${experience.company}`} ref={cardRef}>
                <div className={`relative w-4/5 -mt-[60px] mb-10 rounded-sm px-4 py-2 drop-shadow-lg bg-slate-80`} style={{ float: reverseDirection }}>
                    <div className="ag-timeline-card_inner">
                        <div className="ag-timeline-card_info">
                            <div className="flex flex-row justify-between align-center">
                                <span className="text-xl font-semibold text-center">{experience.title}</span>
                            </div>
                            <span className="italic">{experience.range}</span>
                            <p className='mt-2 leading-normal text-slate-400 '>
                                {experience.summary}
                            </p>
                        </div>
                    </div>
                    {/* <div className={`z-[-1] h-[18px] w-[18px] mt-[20px] absolute top-0 -${reverseDirection}-2 rotate-45`}></div> */}
                </div>
            </div>
        )
    }

    const Title = () => {
        return (
            <div className="w-1/2 relative">
                <div className={`-mt-[60px] absolute`} style={{ left: left ? 0 : 'left', right: left ? 'right' : 0 }}>
                    <div className="mt-[10px] text-2xl font-bold">
                        {experience.company}
                    </div>
                </div>
            </div>
        )
    }



    // useEffect(() => {
    //     if (nodeRef.current) {
    //         let tempNodePosition = Math.round(nodeRef.current.getBoundingClientRect().top);
    //         setNodePosition(tempNodePosition);

    //         if (!movingNode) {

    //             if (tempNodePosition + 3 < scrollTop && !nodeIn) {
    //                 setMovingNode(true);
    //                 setNodeIn(true);
    //                 console.log('moving in')
    //                 cardRef.current!.style.backgroundColor = '#fff';
    //                 console.log(cardRef.current!.style.backgroundColor)
    //                 anime(
    //                     {
    //                         targets: cardRef.current!,
    //                         translateX: -1000,
    //                         easing: 'easeOutCirc',
    //                         end: () => {
    //                             console.log('bing bong')
    //                             setMovingNode(false);
    //                         }
    //                     }
    //                 );
    //             } else if (tempNodePosition + 3 > scrollTop && nodeIn) {
    //                 setMovingNode(true);
    //                 setNodeIn(false);
    //                 console.log('moving out');
    //                 anime(
    //                     {
    //                         targets: `#experience-${experience.company}`,
    //                         translateX: 250,
    //                         delay: anime.stagger(200),
    //                         easing: 'easeOutCirc',
    //                         end: () => {
    //                             setMovingNode(false);
    //                         }
    //                     }
    //                 );
    //             }
    //         } else {
    //             console.log('moving node')
    //         }
    //     }
    // }, [scrollTop, experience.company, movingNode, nodeIn])
    return (
        <div className="relative">
            {/* node */}
            <div className="flex justify-center">
                <div className="js-timeline-card_point-box inline-block">
                    <div className="" >
                        <img alt={`${experience.company} logo`} className="h-[58px] leading-[50px] w-[58px] text-center rounded-full text-xl relative" src={experience.img} ref={nodeRef} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-24 w-full">
                {left ?
                    <>
                        <Card />
                        <Title />
                    </> :
                    <>
                        <Title />
                        <Card />
                    </>}
            </div>
        </div>
    )
}

const ExperienceSection = () => {
    const lineContainerRef = useRef<HTMLDivElement | null>(null);
    const lineRef = useRef<HTMLDivElement | null>(null);
    const [scrollTop, setScrollTop] = useState<number>(0);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            // var scrollPercent = lineContainerRef.current?.scrollHeight ? 100 * lineContainerRef.current.scrollTop / (lineContainerRef.current.scrollHeight - lineContainerRef.current.clientHeight) : 0;
            //var scrollPercentage = 100 * containeR.scrollTop / (containeR.scrollHeight-containeR.clientHeight); 
            // bounding rect.top is the top relative to the viewport
            let tempScrollTop = lineContainerRef.current!.getBoundingClientRect().top - window.innerHeight / 2;
            tempScrollTop = Math.round(Math.min(Math.abs(Math.min(0, tempScrollTop)), lineContainerRef.current!.clientHeight));
            setScrollTop(lineRef.current!.getBoundingClientRect().bottom);
            let scrollPercent = 100 * (tempScrollTop) / (lineContainerRef.current!.clientHeight);

            lineRef.current!.style.height = Math.round(lineContainerRef.current!.clientHeight * scrollPercent / 100) + "px";
            return;
        })
    }, [])
    const experiences = [
        {
            title: "Automation Engineer Intern",
            company: "Qorvo",
            year: "2023",
            range: ["May 2023 - Present"],
            data: [
                "Developed constraint-aware placement algorithms for optimizing microchip architecture.",
                "Automated multiple semiconductor design workflows, improving development cycle time by 30%.",
                "Created automated documentation framework for multiple programming languages, enabling the quick generation of high-quality, publishable documentation."
            ],
            summary: "Engineered cutting-edge algorithms for optimizing microchip architecture. Automated semiconductor design workflows, reducing development time by 30%. Created a versatile automated documentation framework for quick, high-quality output across multiple programming languages.",
            img: Qorvo
        },
        {
            title: "Full Stack Software Engineer",
            company: "Bentley Systems",
            year: "2022",
            range: ["May 2022 - December 2022"],
            data: [
                "Primary developer of ProjectWise, a cloud-based infrastructure software for managing construction assets.",
                "Created an automated test data maintenance system to ensure project data is properly managed.",
                "Produced front and back-end unit, integration, and end-to-end tests alongside application development."
            ],
            summary: "Worked on the development of ProjectWise, a cloud-based infrastructure software used for construction asset management. Created an automated test data maintenance system to ensure precise project data management. Produced unit, integration, and end-to-end tests for both front and back-end during application development.",
            img: Bentley
        },
        {
            title: "Software Engineer Intern",
            company: "JHU Applied Physics Lab",
            year: "2021",
            range: ["May 2021 - October 2021"],
            data: [
                "Designed a ground-up framework (nicknamed QAS) for parsing and scheduling naval task data.",
                "Researched and developed novel methods for handling and carrying out time-sensitive tasks with AI Planning and Scheduling.",
                "Simulated naval missions using QAS, enabling further research and development in complex task automation for aquatic vessels."
            ],
            summary: "Developed QAS, a comprehensive framework for parsing and scheduling naval task data from scratch. Researched and implemented innovative AI Planning and Scheduling techniques for time-sensitive task handling. Conducted simulated naval missions using QAS, facilitating further research in automating complex tasks for aquatic vessels.",
            img: APL
        },
    ]
    return (
        <Section id="experience-section">
            <div className="overflow-x-hidden">
                <div className="py-[200px]">
                    <div className="text-center mb-10">
                        <div className="text-4xl font-semibold">Experience</div>
                    </div>
                    <div className="w-[1160px] mx-auto relative">
                        <div className="js-timeline inline-block w-full max-w-full mx-auto">
                            {/* line */}
                            <div className="z-[-2] js-timeline_line w-[2px] bg-[#C6C7D2] absolute top-[2px] left-1/2 bottom-0 overflow-hidden transform -translate-1/2" ref={lineContainerRef}>
                                <div className="js-timeline_line-progress w-full h-1/5 bg-[#7e99b4]" ref={lineRef}></div>
                            </div>
                            <div className="flex justify-center flex-col">
                                {experiences.map((experience, i) => {
                                    return (
                                        <TimelineNode key={i} experience={experience} left={i % 2 === 0} scrollTop={scrollTop} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default ExperienceSection